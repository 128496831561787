import {Observable} from "rxjs";
import {UserProfileData} from "./user";
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { COMMON_CONSTANT } from "../../../constants/common-keys.constants";
import {KeycloakProfile} from "keycloak-js/lib/keycloak";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  httpOptions={
    headers: new HttpHeaders({
      'Content-Type':'application/json',
    })
  }

  constructor(private http: HttpClient) { }

  getUserDetail(): Observable<UserProfileData> {
    return this.http.get<UserProfileData>(`${COMMON_CONSTANT.API_BASE_URL}/profile/me`);
  }

  getAllUsers(pageNumber: number, query: string,includeInactive:boolean): Observable<any>{
    return this.http.get<any>(`${COMMON_CONSTANT.API_BASE_URL}/users`,{
      params: new HttpParams()
        .set('page', pageNumber)
        .set('query', query)
        .set('includeInactive', includeInactive)
    });
  }

  postUser(payload:UserProfileData):Observable<UserProfileData>{
    return this.http.post<UserProfileData>(`${COMMON_CONSTANT.API_BASE_URL}/users`,payload);
  }

  putUser(id:string,payload:UserProfileData):Observable<UserProfileData>{
    return this.http.put<UserProfileData>(`${COMMON_CONSTANT.API_BASE_URL}/users/${id}`,payload)
  }

  updateRole(id:string,role:string):Observable<UserProfileData>{
    return this.http.put<UserProfileData>(`${COMMON_CONSTANT.API_BASE_URL}/users/${id}/role`,role)
  }
  getSingleUser(id:string):Observable<UserProfileData>{
    return this.http.get<UserProfileData>(`${COMMON_CONSTANT.API_BASE_URL}/users/${id}`)
  }

  deleteUser(id:string){
    return this.http.delete(`${COMMON_CONSTANT.API_BASE_URL}/users/${id}`,this.httpOptions)
  }

  inviteNewUser(email:string){
    return this.http.post(`${COMMON_CONSTANT.API_BASE_URL}/members/invite`,email);
  }
  getUserProfile(token: string): Observable<KeycloakProfile> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json'
    });

    return this.http.get<KeycloakProfile>(`${environment.keycloak.keycloakUrl}/realms/${environment.keycloak.realm}/protocol/openid-connect/userinfo`, {headers});
  }


}
