import {inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {KeycloakProfile} from "keycloak-js/lib/keycloak";
import {UserService} from "./user/user.service";
import {KeycloakProfileUser} from "./user/user";


@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  private userService: UserService = inject(UserService);
  public userProfile: KeycloakProfile | null = null;

  private userSubject = new BehaviorSubject<any | null>(null);
  public user$ = this.userSubject.asObservable();
  constructor(private http: HttpClient) {}

  getUserInfo(token: string) {
    this.userService.getUserProfile(token).subscribe({
      next: (value: any) => {
        console.log('User details in getUserInfo:', value);
        this.userProfile = value;
        this.setUser(value);
      },
      error: (err: any) => {
        console.log('Something bad happened:', err);
      }
    });
  }

  getUser(): Observable<KeycloakProfileUser> {
    return this.user$;
  }

  setUser(user: KeycloakProfile): void {
    if (this.userSubject.getValue() !== user) {
      this.userSubject.next(user);
    }
  }
}
