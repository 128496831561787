export const environment = {
  production: false,
  url: 'https://api.carvia-test.org',
  lfyAuth: 'https://api.carvia-test.org/listingfy-auth',
  basicAuth: 'YWNtZTphY21lc2VjcmV0',
  keycloak: {
    keycloakUrl: 'https://sso.carvia-test.org/',
    realm: 'aibrains',
    clientId: 'aibrains-web'
  },
};
